import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

export type Meta = {
  content: string;
} & ({ property: string } | { name: string });

export interface SEOProps {
  title: string;
  description?: string;
  meta?: Meta[];
}

const SEO: React.VFC<SEOProps> = ({ title, description, meta }) => {
  const { site } = useStaticQuery(query);

  const image = site.siteMetadata.image;
  const keywords = site.siteMetadata.keywords;
  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  const og = site.siteMetadata.og;
  const twitter = site.siteMetadata.twitter;

  return (
    <Helmet
      title={title}
      htmlAttributes={{ lang: `en` }}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keywords,
        },
        {
          property: `og:type`,
          content: og.type,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: og.url,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: "og:image:type",
          content: og.image.type,
        },
        {
          property: "og:image:width",
          content: og.image.width,
        },
        {
          property: "og:image:height",
          content: og.image.height,
        },
        {
          property: "og:image:alt",
          content: og.image.alt,
        },
        {
          property: `og:site_name`,
          content: og.site_name,
        },
        {
          name: `twitter:card`,
          content: twitter.card,
        },
        {
          name: `twitter:image`,
          content: image,
        },
        {
          property: "twitter:image:alt",
          content: og.image.alt,
        },
        {
          name: `twitter:creator`,
          content: twitter.creator,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
};
const query = graphql`
  {
    site {
      siteMetadata {
        title
        description
        keywords
        image
        og {
          type
          url
          site_name
          image {
            type
            width
            height
            alt
          }
        }
        twitter {
          card
          site
          creator
        }
      }
    }
  }
`;

SEO.defaultProps = {
  meta: [],
  description: ``,
};

export default SEO;
