import * as React from "react";
import cx from "classnames";

const Container: React.FC<React.ComponentPropsWithoutRef<"div">> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <div
      className={cx(
        className,
        "container mx-auto px-4 sm:px-6 lg:px-8 xl:px-12"
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Container;
