import * as React from "react";
import FooterBar from "./footerbar";
import Arrow from "../assets/svg/hash-arrow.inline.svg";
import { stripAddress } from "../utils/helpers";

const Footer: React.VFC = () => {
  return (
    <footer className="w-screen mt-10">
      {/*<div className="flex flex-col items-center space-y-2 sm:space-y-5">*/}
      {/*  <h4 className="subheader-text-2 text-white">*/}
      {/*    Verified Implementation Contract*/}
      {/*  </h4>*/}
      {/*  <div className="flex items-center space-x-2">*/}
      {/*    <a className="underline-text text-white" href="#">*/}
      {/*      {stripAddress("0x9d22fd3c176dde9e1a8c4e0a5408025154002080")}*/}
      {/*    </a>*/}
      {/*    <Arrow className="w-6 h-6" />*/}
      {/*  </div>*/}
      {/*</div>*/}
      <FooterBar />
    </footer>
  );
};

export default Footer;
