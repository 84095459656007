import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { motion } from "framer-motion";
import { btnMotionConfig } from "../utils/animation";
import Container from "./container";

import Discord from "../assets/svg/discord-circled.inline.svg";
import Twitter from "../assets/svg/twitter-circled.inline.svg";
import SolanaStamp from "../assets/svg/solana-stamp.inline.svg";

const FooterBar: React.VFC = () => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(query);
  return (
    <div className="w-full p-5 mt-10" style={{ backgroundColor: "#222222" }}>
      <Container className="flex flex-col md:flex-row items-center justify-between">
        <div className="flex justify-center space-x-6 md:order-3">
          <motion.a
            href={siteMetadata.discordUrl}
            target="_blank"
            {...btnMotionConfig}
            className="text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">Discord</span>
            <Discord className="h-8 w-8" aria-hidden="true" />
          </motion.a>
          <motion.a
            href={siteMetadata.twitterUrl}
            target="_blank"
            {...btnMotionConfig}
            className="text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">Twitter</span>
            <Twitter className="h-8 w-8" aria-hidden="true" />
          </motion.a>
        </div>
        <SolanaStamp className="mt-6 md:mt-0 h-6 md:h-8 md:order-2" />
        <div className="mt-4 md:mt-0 md:order-1">
          <p className="text-center text-base text-white">
            &copy; {new Date().getFullYear()} FeatherNFT. All rights reserved.
          </p>
        </div>
      </Container>
    </div>
  );
};

const query = graphql`
  {
    site {
      siteMetadata {
        twitterUrl
        discordUrl
      }
    }
  }
`;

export default FooterBar;
