import * as React from "react";
import SEO, { SEOProps } from "./seo";
import Navbar from "./navbar";
import Footer from "./footer";

import "@solana/wallet-adapter-react-ui/styles.css";
import { Wallet } from "./wallet";
const BalanceProvider = React.lazy(() => import("./balance/balanceProvider"));

interface LayoutProps extends SEOProps {}
const Layout: React.FC<LayoutProps> = ({
  title,
  description,
  meta,
  children,
}) => {
  const isSSR = typeof window === "undefined";

  const content = React.useMemo(
    () => (
      <>
        <Navbar />
        {children}
        <Footer />
      </>
    ),
    []
  );

  return (
    <Wallet>
      <SEO title={title} description={description} meta={meta} />
      {isSSR ? (
        content
      ) : (
        <React.Suspense fallback={<div />}>
          <BalanceProvider>{content}</BalanceProvider>
        </React.Suspense>
      )}
    </Wallet>
  );
};

export default Layout;
