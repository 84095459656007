import * as React from "react";
import { Link } from "gatsby";
import Container from "./container";
import FooterBar from "./footerbar";

import MenuIcon from "../assets/svg/menu-icon.inline.svg";
import CloseIcon from "../assets/svg/close-menu-icon.inline.svg";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

const menu = {
  HOME: "/",
  ABOUT: "/#about",
  MINT: "/#mint",
  ROADMAP: "/#roadmap",
  FAQ: "/#faq",
  STORE: "https://feathernft-store.vercel.app/",
};

const Navbar: React.VFC = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    if (window) {
      const body = document.body;
      const rootElm = document.getElementById("___gatsby");
      if (isOpen) {
        rootElm.classList.add("overflow-hidden");
        body.classList.add("overflow-hidden");
      } else {
        rootElm.classList.remove("overflow-hidden");
        body.classList.remove("overflow-hidden");
      }
    }
  }, [isOpen]);

  const logo = () => (
    <Link to="/" className="header-text text-white font-thin">
      Feathers.
    </Link>
  );

  const connectBtn = () => <WalletMultiButton />;

  const toggleMenu = () => setIsOpen(!isOpen);

  const menuBtn = () => (
    <button className="lg:hidden p-3 sm:p-4 rounded-md" onClick={toggleMenu}>
      {isOpen ? <CloseIcon className="h-6" /> : <MenuIcon className="h-6" />}
    </button>
  );

  return (
    <>
      <div className="fixed top-0 z-50 inset-x-0 bg-app-background-secondary sm:bg-app-background-primary">
        <Container className="flex items-center justify-between py-2 sm:pt-8 sm:pb-6">
          {logo()}
          <nav className="hidden lg:flex items-center space-x-5">
            <ul className="flex items-center space-x-5">
              {Object.entries(menu).map(([label, to]) => (
                <li className="subtitle-text text-white">
                  <Link to={to}>{label}</Link>
                </li>
              ))}
            </ul>
            {connectBtn()}
          </nav>
          {menuBtn()}
        </Container>
      </div>
      {isOpen && (
        <div className="fixed inset-0 bg-black z-50 flex flex-col justify-between">
          <Container className="flex items-center justify-between py-2 sm:pt-8 sm:pb-6">
            {logo()}
            {menuBtn()}
          </Container>
          <Container>
            <nav className="flex flex-col items-center space-y-6">
              <ul className="flex flex-col items-center">
                {Object.entries(menu).map(([label, to]) => (
                  <li className="subtitle-text text-white py-3 inline-block">
                    <Link to={to} onClick={toggleMenu}>
                      {label}
                    </Link>
                  </li>
                ))}
              </ul>
              {connectBtn()}
            </nav>
          </Container>
          <FooterBar />
        </div>
      )}
    </>
  );
};

export default Navbar;
